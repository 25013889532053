/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'powerTools', label: 'Power Tools' },
        { key: 'airToolsAndCompressors', label: 'Air Tools & Compressors' },
        { key: 'automotive', label: 'Automotive' },
        { key: 'vacuums', label: 'Vacuums Wet/Dry' },
        { key: 'weldingAndSoldering', label: 'Welding & Soldering' },
        { key: 'measuringTools', label: 'Levels & Measuring Tools' },
        { key: 'handTools', label: 'Hand Tools' },
        { key: 'laddersAndScaffolding', label: 'Ladders & Scaffolding' },
        { key: '3DPrinters', label: '3D Printers' },
        { key: 'paintEquipment', label: 'Paint Equipment' },
        { key: 'outdoorPowerTools', label: 'Outdoor Power Tools' },
        { key: 'woodworkingTools', label: 'Wood Working Tools' },
        { key: 'plumbingTools', label: 'Plumbing Tools' },
      ],
    },
  },
  // {
  //   id: 'project',
  //   label: 'Project',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_project'],
  //   config: {
  //     options: [
  //       { key: 'tiresAndBrakes', label: 'Changing tires & brakes' },
  //       { key: 'painting', label: 'Painting' },
  //       { key: 'cabinetBuilding', label: 'Cabinet building' },
  //       { key: 'waterCleanup', label: 'Water cleanup' },
  //       { key: 'floorRepairOrRemodels', label: 'Floor repair or remodels' },
  //       { key: 'woodFurniture', label: 'Wood furniture' },
  //       { key: 'lawnAndLandscaping', label: 'Lawn & landscaping' },
  //       { key: 'deckPatioAndFences', label: 'Deck, patio & fences' },
  //       { key: 'autoBodyRepairs', label: 'Auto body repairs' },
  //       { key: 'autoPerformanceUpgrades', label: 'Auto performance upgrades' },
  //       { key: 'metalFabrication', label: 'Metal fabrication' },
  //       { key: 'drainageIssues', label: 'Drainage issues' },
  //     ],
  //   },
  // },
  {
    id: 'brand',
    label: 'Brand',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_brand'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'BOSCH', label: 'BOSCH' },
        { key: 'DELTA', label: 'DELTA' },
        { key: 'DeWALT', label: 'DeWALT' },
        { key: 'HILTI', label: 'HILTI' },
        { key: 'HUSKY', label: 'HUSKY' },
        { key: 'RIDGID', label: 'RIDGID' },
        { key: 'Milwaukee', label: 'Milwaukee' },
        { key: 'RYOBI', label: 'RYOBI' },
        { key: 'Makita', label: 'Makita' },
        { key: 'STANLEY', label: 'STANLEY' },
        { key: 'porterCable', label: 'PORTER CABLE' },
        { key: 'drillAmerica', label: 'Drill America' },
      ],
    },
  },
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      // options: [
        // {
        //   key: 'towels',
        //   label: 'Towels',
        // },
        // {
        //   key: 'bathroom',
        //   label: 'Bathroom',
        // },
        // {
        //   key: 'swimming_pool',
        //   label: 'Swimming pool',
        // },
        // {
        //   key: 'own_drinks',
        //   label: 'Own drinks allowed',
        // },
        // {
        //   key: 'jacuzzi',
        //   label: 'Jacuzzi',
        // },
        // {
        //   key: 'audiovisual_entertainment',
        //   label: 'Audiovisual entertainment',
        // },
        // {
        //   key: 'barbeque',
        //   label: 'Barbeque',
        // },
        // {
        //   key: 'own_food_allowed',
        //   label: 'Own food allowed',
        // },
//       ],
//     },
//   },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
