import Decimal from 'decimal.js';
import { convertMoneyToNumber, convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { dateFromLocalToAPI } from '../../util/dates';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import axios from 'axios';
import { post } from '../../util/api';
import Geocoder from '../LocationAutocompleteInput/GeocoderMapbox';
import config from '../../config';

const { Money, UUID } = sdkTypes;

const getLocationFromLatLng = (lat, lng) => {
  return axios
    .get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
    )
    .then(resp => {
      const context = resp.data.features[0].context;

      const country = context.find(c => c.id.includes('country')).short_code?.toUpperCase();
      const zip = context.find(c => c.id.includes('postcode')).text;
      const state = context
        .find(
          c =>
            (c.id.includes('region') || c.id.includes('place') || c.id.includes('district')) &&
            c.short_code
        )
        ?.short_code?.toUpperCase()
        .replace(`${country}-`, '');

      return {
        country,
        zip,
        state,
      };
    })
    .catch(e => {
      console.log(e);
    });
};

const getGeocoder = () => {
  const geocoder = new Geocoder();

  return geocoder;
};

export const estimatedTotalPrice = lineItems => {
  const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
    const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
    return new Decimal(numericPrice).add(sum);
  }, 0);

  // All the lineItems should have same currency so we can use the first one to check that
  // In case there are no lineItems we use currency from config.js as default
  const currency =
    lineItems[0] && lineItems[0].unitPrice ? lineItems[0].unitPrice.currency : config.currency;

  return new Money(
    convertUnitToSubUnit(numericTotalPrice.toNumber(), unitDivisor(currency)),
    currency
  );
};

export const getTaxInfo = (setTaxInfo, lineItems, listing) => {
  return getGeocoder()
    .getPlaceDetails({
      id: 'current-location',
      predictionPlace: {},
    })
    .then(place => {
      const { lat, lng } = place?.origin || {};
      return getLocationFromLatLng(lat, lng)
        .then(resp => {
          const from_country = resp.country;
          const from_zip = resp.zip;
          const from_state = resp.state;

          const totalPayin =
            lineItems &&
            estimatedTotalPrice(lineItems.filter(item => item.includeFor.includes('customer')));

          const providerLat = listing?.attributes?.geolocation?.lat;
          const providerLng = listing?.attributes?.geolocation?.lng;

          return getLocationFromLatLng(providerLat, providerLng).then(resp => {
            const to_country = resp.country;
            const to_zip = resp.zip;
            const to_state = resp.state;

            post('/api/taxjar', {
              from_country,
              from_zip,
              from_state,
              to_country,
              to_zip,
              to_state,
              totalPayin: totalPayin?.amount,
            })
              .then(resp => {
                setTaxInfo(resp?.tax);
              })
              .catch(e => {
                console.log(e);
              });
          });
        })
        .catch(e => {
          console.log(e);
        });
    })
    .catch(e => console.log(e));
};

export const estimatedTransaction = (lineItems, bookingStart, bookingEnd) => {
  const now = new Date();

  const isCustomer = true; //userRole === 'customer';

  const customerLineItems = lineItems.filter(item => item.includeFor.includes('customer'));
  const providerLineItems = lineItems.filter(item => item.includeFor.includes('provider'));

  const payinTotal = estimatedTotalPrice(customerLineItems);
  const payoutTotal = estimatedTotalPrice(providerLineItems);

  // bookingStart: "Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)"
  // Server normalizes night/day bookings to start from 00:00 UTC aka "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)"
  // The result is: local timestamp.subtract(12h).add(timezoneoffset) (in eg. -23 h)

  // local noon -> startOf('day') => 00:00 local => remove timezoneoffset => 00:00 API (UTC)
  const serverDayStart = dateFromLocalToAPI(
    moment(bookingStart)
      .startOf('day')
      .toDate()
  );
  const serverDayEnd = dateFromLocalToAPI(
    moment(bookingEnd)
      .startOf('day')
      .toDate()
  );

  return {
    id: new UUID('estimated-transaction'),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_REQUEST_PAYMENT,
      payinTotal,
      payoutTotal,
      lineItems: isCustomer ? customerLineItems : providerLineItems,
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: TRANSITION_REQUEST_PAYMENT,
        },
      ],
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: serverDayStart,
        end: serverDayEnd,
      },
    },
  };
};

export const getSalesTax = (lineItems, listing) => {
  return getGeocoder()
    .getPlaceDetails({
      id: 'current-location',
      predictionPlace: {},
    })
    .then(place => {
      const { lat, lng } = place?.origin || {};
      return getLocationFromLatLng(lat, lng)
        .then(resp => {
          const from_country = resp.country;
          const from_zip = resp.zip;
          const from_state = resp.state;

          const totalPayin =
            lineItems &&
            estimatedTotalPrice(lineItems.filter(item => item.includeFor.includes('customer')));

          const providerLat = listing?.attributes?.geolocation?.lat;
          const providerLng = listing?.attributes?.geolocation?.lng;

          return getLocationFromLatLng(providerLat, providerLng).then(resp => {
            const to_country = resp.country;
            const to_zip = resp.zip;
            const to_state = resp.state;

            return post('/api/taxjar', {
              from_country,
              from_zip,
              from_state,
              to_country,
              to_zip,
              to_state,
              totalPayin: totalPayin?.amount,
            })
              .then(resp => {
                return resp;
              })
              .catch(e => {
                console.log(e);
              });
          });
        })
        .catch(e => {
          console.log(e);
        });
    })
    .catch(e => console.log(e));
};
