import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { post } from '../../util/api';
import { NamedLink } from '../../components';
import powertoolsImg from './powertools.jpg';
import chainsawImg from './chainsaw.jpg';
import garageImg from './garage.jpg';
import mitresawImg from './mitresaw.jpg';

import css from './SectionLocations.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

// const LazyImage = lazyLoadWithDimensions(LocationImage);

// const locationLink = (name, image, searchQuery) => {
//   const nameText = <span className={css.locationName}>{name}</span>;
//   return (
//     <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
//       <div className={css.imageWrapper}>
//         <div className={css.aspectWrapper}>
//           <LazyImage src={image} alt={name} className={css.locationImage} />
//         </div>
//       </div>
//       <div className={css.linkText}>
//         <FormattedMessage
//           id="SectionLocations.listingsInLocation"
//           values={{ location: nameText }}
//         />
//       </div>
//     </NamedLink>
//   );
// };

const SectionLocations = props => {
  const { rootClassName, className, currentUser, history } = props;

  const classes = classNames(rootClassName || css.root, className);

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const sendEmail = () => {
    const requestOptions = {
      subject: `Lenddit | ${email} requested more info`,
      text: `${email} submitted his email to the "Start earning money" section from homepage.`,
    };

    return post('/api/sendgrid-email', requestOptions)
      .then(resp => {
        setEmail('');
        setSuccess(true);
        setTimeout(() => {
          history.push(`/signup?email=${email}`);
        }, 1000);
      })
      .catch(e => {
        setError(true);
        console.log(error.stack);
      });
  };

  return (
    <div className={classes}>
      <div className={css.sweepstakesBox}>
          <h2>Congratulations to our Sweepstakes Grand Prize Winners!</h2>
          <button className={css.buttongiveaway} onClick={() => window.location.href = 'https://www.lenddit.com/giveaway-winners'}>
            Click here to see the winners!
          </button>
        </div>
      <div className={css.wrapper}>
         
        <div className={css.box}>
          
          <h2>Generate money renting your tools and equipment. Sign up here!</h2>
          {success ? (
            <div className={css.row}>
              <p className={css.successText}>
                Your request was successfully sent! <br />
                We will come back to you with more information
              </p>
            </div>
          ) : (
            <div className={css.row}>
              <input
                type="email"
                className={css.input}
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                placeholder="Your email address"
              />
              <button className={css.button} onClick={sendEmail}>
                Start Earning Cash
              </button>
            </div>
          )}
        </div>
        <div className={css.complementaryBox}>
          <div>
            <h2 className={css.title1}>Trust in Lenddit – Your Tools Are Secure with Us!</h2>
            <p className={css.text}>
              At Lenddit, we understand the value of your equipment and are dedicated to keeping it
              safe and secure. That's why we offer comprehensive damage protection to ensure that
              your tools are covered in case of accidents. With Lenddit, you can lend and borrow
              with complete peace of mind, knowing that we've got your back every step of the way.
            </p>
            <br />
            <h2 className={css.title}>Why rent your equipment?</h2>
            <p className={css.text}>
              Don't let your tools gather dust—turn them into cash generators! By joining Lenddit,
              you can rent out your idle equipment quickly and easily, adding a new revenue stream
              to your household. Experience the satisfaction of seeing your tools put to good use,
              all while putting money back in your pocket. Make your tools work for you; join
              Lenddit today.
            </p>
          </div>
        </div>
        

      </div>
      {/*<div className={css.imageBox}>
        <div className={css.imageGrid}>
          <img src={chainsawImg} alt="Chainsaw" className={css.horizontalImage} />
          <img src={powertoolsImg} alt="Power Tools" className={css.verticalImage} />
          <img src={mitresawImg} alt="Mitre Saw" className={css.verticalImage} />
          <img src={garageImg} alt="Garage" className={css.horizontalImage} />
        </div>
       </div>*/}

    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;

