import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.text}>
      The words and phrases used in this policy have the same meaning as in the Terms & Conditions available at <a href="/terms-of-service"> www.lenddit.com/terms-of-service</a>. The terms “we”, “our”, and “us” refer to LENDDIT, INC. The terms "user", "individual", or "you" as used in this Privacy Policy, refer to any user (including any Owners or Renters) providing personal data within the Website in connection with any of the Services. <br/><br/>By accessing or using our Website, you agree to be bound by this Privacy Policy and the Terms and Conditions. Do not use this Website if you do not agree to these conditions. 
      </p>
      <h2>1.&nbsp;&nbsp;  Third Party Sites. </h2>

      <p className={css.text}>
      We may post links to third party websites on the Website. These third-party websites are not screened for privacy or security issues by LENDDIT, and you release us from any liability for the conduct of these third-party websites. Please be aware that this Privacy Policy, and any other policies in place, in addition to any amendments, does not create rights enforceable by third parties or require disclosure of any personal information relating to members of the Services or Website. LENDDIT bears no responsibility for the information collected or used by any advertiser or third-party website. Please review the privacy policy and terms of service for each site you visit through third-party links.

      </p>

      <h2>2.&nbsp;&nbsp;Changes to Policy.</h2>
      <p className={css.text}>
      This policy may be amended to reflect regulatory requirements, advances in technology, Website updates and changes in our personal information management practices. Any new policy will automatically be effective when it is published on the Website.  By continuing to use this Website, you agree to the amended terms of this Policy.  If you do not agree to the terms of this Policy, do not use this Website and do not provide us with any personal information.  

      </p>

      <h2>3.&nbsp;&nbsp;Children’s Information.</h2>
      <p>
      Use of this Website is intended for adults at least eighteen (18) years of age. You acknowledge that you are eighteen (18) years of age or older. We will not knowingly collect personally identifying information from children under the age of thirteen (13). LENDDIT endeavors to adhere to the provisions of the Children's Online Privacy Protection Act ("COPPA").
      </p>

      <h2>4.&nbsp;&nbsp;Information Collected.  </h2>
      <p className={css.text}> 
      We collect certain personal information from you when you sign up to our Service that can be used to identify you, such as your name, e-mail address, mailing address, credit card information, telephone number, and any other information that we deem necessary for the purpose of providing you with our Service or which you provide to us voluntarily. The information we collect from you, to the extent that it is private, is disclosed only in accordance with our Terms of Service and/or this Privacy Policy.
      <br/><br/>
      Whenever you visit our Site, we may collect non-identifying information from you, such as your IP address, referring URL, browser, operating system, cookie information, and Internet Service Provider. Without a subpoena, voluntary compliance on the part of your Internet Service Provider, or additional records from a third party, this information alone cannot usually be used to identify you.
      </p>
      <h2>5.&nbsp;&nbsp;Use of your Information.</h2>
      <p className={css.text}>
      We may use or disclose your information to:<br/><br/>
      •	Enhance or improve User experience, our Website, or our Service;<br/>
•	To customize, measure, report on and improve our services, content and advertising;<br/>
•	Process transactions and payments;<br/>
•	Send e-mails about our Website or respond to inquiries;<br/>
•	Communicate to Renters and Owners; <br/>
•	Send e-mails and updates about LENDDIT, including news and notices regarding updates to this Privacy Policy and our Terms & Conditions; <br/>
•	To promote services related to the Website or LENDDIT; <br/>
•	To compare information for accuracy, and verify it with third parties;<br/>
•	To prevent, investigate or prosecute activity we think may be potentially illegal, unlawful or harmful; and<br/>
•	Perform any other function that we believe in good faith is necessary to protect the security or proper functioning of our Website or Service.
      </p>
      <h2>6.&nbsp;&nbsp;Release of your Information for Legal Purposes. </h2>
      <p className={css.text}>
      At times, it may become necessary or desirable to LENDDIT, for legal purposes, to release your information in response to a request from a government agency or a private litigant. You agree that we may disclose your information to a third party where we in our sole discretion believe, in good faith, that it is necessary or desirable to do so for the purposes of a civil action, criminal investigation, or other legal matter. In the event that we receive a subpoena affecting your privacy, we may elect to notify you to give you an opportunity to file a motion to quash the subpoena, or we may attempt to quash it ourselves, but we are not obligated to do either. We may also proactively report you, and release your information to, third parties where we believe that it is prudent to do so for legal reasons, such as our belief that you have engaged in fraudulent activities. You release us from any damages that may arise from or relate to the release of your information to a request from law enforcement agencies or private litigants.
      </p>
      <h2>7.&nbsp;&nbsp;Third Party Access to Your Information.  </h2>
      <p className={css.text}>
      Although you are entering into an agreement with LENDDIT to disclose your information to us, we do use third party individuals and organizations to assist us, including contractors, web hosts, and others. Throughout the course of our provision of our Service to you, we may delegate our authority to collect, access, use, and disseminate your information. For example, our web host stores the information that you provide us, and we may hire outside contractors to perform maintenance or assist us in securing our website. It is therefore necessary that you grant the third parties we may use in the course of our business the same rights that you afford us under this Privacy Policy. For this reason, you hereby agree that for every authorization which you grant to us in this Privacy Policy, you also grant to any third-party that we may hire, contract, or otherwise retain the services of for the purpose of operating, maintaining, repairing, or otherwise improving or preserving our website or its underlying files or systems. You agree not to hold us liable for the actions of any of these third parties, even if we would normally be held vicariously liable for their actions, and that you must take legal action against them directly should they commit any tort or other actionable wrong against you.
      </p>
      <h2>8.&nbsp;&nbsp;Access. </h2>
      <p className={css.text}>
      Users may in some cases be able to review and edit personal information they have provided to us by logging into your account on the Website and editing their account. Although most changes may occur immediately, information may still be stored in a web browser’s cache. We take no responsibility for stored information in your cache, or in other devices that may store information, and disclaim all liability of such. In addition, we may, from time to time, retain residual information about you in our backup and/or database.  If you ever want to know what personal information has been collected or wish to correct entered personal information that may not be possible through your account management page, please contact Customer Support.
      </p>
      <h2>9.&nbsp;&nbsp;Security. </h2>
      <p className={css.text}>
      We take certain measures to enhance the security of our Website and Service. However, we make no representations as to the security or privacy of your information. It is in our interest to keep our Website secure, but we recommend that you use anti-virus software, firewalls, and other precautions to protect yourself from security threats.
      </p>
      <h2>10.&nbsp;&nbsp;International Transfer. </h2>
      <p className={css.text}>
      Your information may be transferred to, and maintained on, computers located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      </p>
      <h2>11.&nbsp;&nbsp;Commercial and Non-Commercial Communications. </h2>
      <p className={css.text}>
      By providing your contact information to us through the Site, you agree to receive communications from us, or anyone else covered under this Privacy Policy, and you waive all rights to file complaints concerning unsolicited e-mails from LENDDIT. However, you may unsubscribe from certain communications by notifying LENDDIT that you no longer wish to receive solicitations or information and we will endeavor to remove you from the database.
      </p>
      <h2>12.&nbsp;&nbsp;Notice to EU Individuals. </h2>
      <p className={css.text}>
      This Privacy Policy is intended to comply with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (the “General Data Protection Regulation” or “GDPR”) and provide appropriate protection and care with respect to the treatment of your user information in accordance with the GDPR. We will not process your user information without a lawful basis to do so, as such bases are defined in Article 6 of the GDPR. In general, we will process your user information on the legal bases of consent, contract (if you have entered into an agreement with us and such processing is a necessary part thereof), or legal obligation (as noted below). We may also process certain user information on the basis of the following legitimate interests, provided that such interests are not overridden by your privacy rights and interests: delivering and continuing to develop and improve the Website, learning from your behavior on the Website (e.g., analyzing traffic) to better serve you and other users, helping us modify or enhance the Website and its content, and providing a stable, consistent, and secure user experience in connection with the Website.<br/><br/>
      In accordance with the GDPR, you may have certain additional rights, including:
    <br/><br/>
    •	Right of access to personal data.<br/>
•	Right to correct personal data where it is incorrect of incomplete.<br/>
•	Right of erasure of personal data (“right to be forgotten”).<br/>
•	Right to restrict/suspend processing of personal data.<br/>
•	Right to complain to a supervisory authority.<br/>
•	Right to data portability.<br/>
•	Right to withdraw consent.<br/>
•	Right to object to processing.<br/>
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
