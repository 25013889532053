import { types as sdkTypes } from './sdkLoader';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const { UUID } = sdkTypes;

export const getListingRatingAvg = listingId => {
  return sdk.reviews
    .query({
      listingId: new UUID(listingId),
    })
    .then(resp => {
      const reviews = resp.data.data;
      const reviewsWithRating = reviews
        .filter(r => r.attributes.rating)
        .map(r => r.attributes.rating);

      if (reviewsWithRating.length > 0) {
        const reviewsSum = reviewsWithRating.reduce((a, b) => a + b, 0);
        const reviewsAvg = (reviewsSum / reviewsWithRating.length).toFixed(1);

        return {
          ratingAvg: Number(reviewsAvg),
          reviewsCount: reviewsWithRating.length,
        };
      } else {
        return 0;
      }
    })
    .catch(e => {
      return 0;
    });
};
