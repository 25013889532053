import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import {
  Form,
  LocationAutocompleteInput,
  FieldSelect,
  FieldDateRangeInput,
  FieldTextInput,
} from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import { maxLength, required, composeValidators } from '../../util/validators';
import config from '../../config';
import css from './TopbarSearchForm.module.css';
import IconHourGlass from '../../components/LocationAutocompleteInput/IconHourGlass';
import { stringifyDateToISO8601 } from '../../util/dates';
const identity = v => v;

Date.prototype.removeDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

Date.prototype.toSearchDate = function(days) {
  var date = new Date(this.valueOf());
  date.setHours(0);
  date.setMinutes(0);
  date.setMilliseconds(0);
  const year = date.getFullYear();
  const rowMonth = date.getMonth() + 1;
  const month = rowMonth < 10 ? '0' + rowMonth.toString() : rowMonth;
  const day = date.getDate();
  const fullDate = year + '-' + month + '-' + day;
  return fullDate;
};
class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
  }

  onChange(location) {
    // if (location.selectedPlace) {
    // Note that we use `onSubmit` instead of the conventional
    // `handleSubmit` prop for submitting. We want to autosubmit
    // when a place is selected, and don't require any extra
    // validations for the form.
    //  this.props.onSubmit({ location });
    // blur search input to hide software keyboard
    //   if (this.searchInput) {
    //     this.searchInput.blur();
    //   }
    // }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            unitType,
            isMobile,
            values,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();
          const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

          const handleFormSubmit = e => {
            e.preventDefault();
            let finalValues = {};
            if (values.location) {
              finalValues.location = values.location;
            }
            if (values.keywords) {
              finalValues.keywords = values.keywords;
            }

            if (values.bookingDates) {
              const startDate = values.bookingDates.startDate;
              const startDateStringify = stringifyDateToISO8601(startDate);

              const endDate = values.bookingDates.endDate;
              const endDateStringify = stringifyDateToISO8601(endDate);

              const finalDatesSearchString = startDateStringify + ',' + endDateStringify;

              finalValues.dates = finalDatesSearchString;
            }
            this.props.onSubmit({ ...finalValues });
          };

          const categoryRequired = required(
            intl.formatMessage({
              id: 'Field.categoryRequired',
            })
          );

          return (
            <Form
              className={classes}
              onSubmit={handleFormSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              <FieldTextInput
                className={css.textField}
                name={'keywords'}
                id={`keywords`}
                type="text"
                placeholder={'Keywords'}
              />
              {/* <FieldSelect
                id="category"
                name="category"
                className={css.type}
                // validate={categoryRequired}
              >
                <option value="" hidden disabled>
                  Type of Equipment
                </option>
                {categoryOptions.map(o => {
                  return <option value={o.key}>{o.label}</option>;
                })}
              </FieldSelect> */}

              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    this.onChange(value);
                  };

                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    <LocationAutocompleteInput
                      className={css.location}
                      iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                      inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                      predictionsClassName={
                        isMobile ? css.mobilePredictions : css.desktopPredictions
                      }
                      predictionsAttributionClassName={
                        isMobile ? css.mobilePredictionsAttribution : null
                      }
                      placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                      closeOnBlur={!isMobile}
                      inputRef={node => {
                        this.searchInput = node;
                      }}
                      input={searchInput}
                      meta={meta}
                    />
                  );
                }}
              />

              <FieldDateRangeInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`bookingStartDate`}
                startDatePlaceholderText={'Rent from'}
                endDateId={`bookingEndDate`}
                endDatePlaceholderText={'Rent to'}
                useMobileMargins
                allDatesAvailable={true}
              />

              <button className={css.searchButtonIcon}>
                <IconHourGlass />
              </button>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  unitType: config.bookingUnitType,
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
