import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { pushToPath } from '../../util/urlHelpers';
import { NamedLink } from '../../components';

import css from './SectionHowItWorks.module.css';
import { Carousel } from 'react-responsive-carousel';
import SlideShow from './SlideShow';

const SectionHowItWorks = props => {
  const { rootClassName, className, history } = props;

  useEffect(() => {
    require('react-responsive-carousel/lib/styles/carousel.min.css');
  }, []);
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.wrapper1}>
        <div>
          <SlideShow history={history} />
        </div>
      </div>
      {/* <div className={css.wrapper2}>
        <div>
          <h2 className={css.title}>Tools by Project</h2>
          <div className={css.row}>
            <div
              onClick={() => pushToPath('/s?pub_project=tiresAndBrakes')}
              className={css.littleBox}
            >
              <p>Changing tires & brakes</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_project=painting')} className={css.littleBox}>
              <p>Painting</p>
            </div>
            <div
              onClick={() => pushToPath('/s?pub_project=cabinetBuilding')}
              className={css.littleBox}
            >
              <p>Cabinet building</p>
            </div>
            <div
              onClick={() => pushToPath('/s?pub_project=waterCleanup')}
              className={css.littleBox}
            >
              <p>Water cleanup</p>
            </div>
          </div>
          <div className={css.row}>
            <div
              onClick={() => pushToPath('/s?pub_project=floorRepairOrRemodels')}
              className={css.littleBox}
            >
              <p>Floor repair or remodels</p>
            </div>
            <div
              onClick={() => pushToPath('/s?pub_project=woodFurniture')}
              className={css.littleBox}
            >
              <p>Wood furniture</p>
            </div>
            <div
              onClick={() => pushToPath('/s?pub_project=lawnAndLandscaping')}
              className={css.littleBox}
            >
              <p>Lawn & landscaping</p>
            </div>
            <div
              onClick={() => pushToPath('/s?pub_project=deckPatioAndFences')}
              className={css.littleBox}
            >
              <p>Deck, patio & fences</p>
            </div>
          </div>
          <div className={css.row}>
            <div
              onClick={() => pushToPath('/s?pub_project=autoBodyRepairs')}
              className={css.littleBox}
            >
              <p>Auto body repairs</p>
            </div>
            <div
              onClick={() => pushToPath('/s?pub_project=autoPerformanceUpgrades')}
              className={css.littleBox}
            >
              <p>Auto performance upgrades</p>
            </div>
            <div
              onClick={() => pushToPath('/s?pub_project=metalFabrication')}
              className={css.littleBox}
            >
              <p>Metal fabrication</p>
            </div>
            <div
              onClick={() => pushToPath('/s?pub_project=drainageIssues')}
              className={css.littleBox}
            >
              <p>Drainage issues</p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className={css.wrapper2}>
   
          <h2 className={css.title}>Tools by Brand</h2>
          <div className={css.row}>
            <div onClick={() => pushToPath('/s?pub_brand=BOSCH')} className={css.littleBox}>
              <p>BOSCH</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=DELTA')} className={css.littleBox}>
              <p>DELTA</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=DeWALT')} className={css.littleBox}>
              <p>DeWALT</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=HILTI')} className={css.littleBox}>
              <p>HILTI</p>
            </div>
          </div>
          <div className={css.row}>
            <div onClick={() => pushToPath('/s?pub_brand=HUSKY')} className={css.littleBox}>
              <p>HUSKY</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=RIDGID')} className={css.littleBox}>
              <p>RIDGID</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=Milwaukee')} className={css.littleBox}>
              <p>Milwaukee</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=RYOBI')} className={css.littleBox}>
              <p>RYOBI</p>
            </div>
          </div>
          <div className={css.row}>
            <div onClick={() => pushToPath('/s?pub_brand=Makita')} className={css.littleBox}>
              <p>Makita</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=STANLEY')} className={css.littleBox}>
              <p>STANLEY</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=porterCable')} className={css.littleBox}>
              <p>PORTER CABLE</p>
            </div>
            <div onClick={() => pushToPath('/s?pub_brand=drillAmerica')} className={css.littleBox}>
              <p>Drill America</p>
            </div>
  
        </div>
      </div> */}
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
