import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pushToPath } from '../../util/urlHelpers';

import { Carousel } from 'react-responsive-carousel';
import Geocoder, {
  GeocoderAttribution,
  CURRENT_LOCATION_ID,
} from '../LocationAutocompleteInput/GeocoderMapbox';
import { parse, stringify } from '../../util/urlHelpers';

import css from './SectionHowItWorks.module.css';
import './carousel.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';

const SlideShow = props => {
  const { history } = props;

  const [currentLocation, setCurrentLocation] = useState(null);

  const getGeocoder = () => {
    const geocoder = new Geocoder();

    return geocoder;
  };
  useEffect(() => {
    getGeocoder()
      .getPlaceDetails({
        id: 'current-location',
        predictionPlace: {},
      })
      .then(place => {
        place.address = 'Current location';
        const currentLocationObject = {
          search: place.address,
          predictions: [],
          selectedPlace: place,
        };
        if (currentLocationObject?.selectedPlace) {
          setCurrentLocation(currentLocationObject);
        }
      })
      .catch(e => console.log(e));
  }, []);

  useEffect(() => {
    require('react-responsive-carousel/lib/styles/carousel.min.css');
  }, []);

  const handleSearch = data => {
    const { currentSearchParams } = props;

    let locationObj = {};

    const search = currentLocation?.search;
    const selectedPlace = currentLocation?.selectedPlace;
    const origin = selectedPlace?.origin;
    const bounds = selectedPlace?.bounds;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    locationObj = {
      ...originMaybe,
      address: search,
      bounds,
    };

    const { history } = props;

    const searchParams = {
      ...locationObj,
      ...data,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div>
      <h2 className={css.title}>Categories</h2>
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        emulateTouch={true}
      >
        <div className={css.row}>
          <div
            onClick={() => handleSearch({ pub_category: 'powerTools' })}
            className={css.littleBox}
          >
            <p>Power Tools</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'airToolsAndCompressors' })}
            className={css.littleBox}
          >
            <p>Air Tools & Compressors</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'automotive' })}
            className={css.littleBox}
          >
            <p>Automotive</p>
          </div>
          <div onClick={() => handleSearch({ pub_category: 'vacuums' })} className={css.littleBox}>
            <p>Vacuums Wet/Dry</p>
          </div>
        </div>
        <div className={css.row}>
          <div
            onClick={() => handleSearch({ pub_category: 'weldingAndSoldering' })}
            className={css.littleBox}
          >
            <p>Welding & Soldering</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'measuringTools' })}
            className={css.littleBox}
          >
            <p>Levels & Measuring Tools</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'handTools' })}
            className={css.littleBox}
          >
            <p>Hand Tools</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'laddersAndScaffolding' })}
            className={css.littleBox}
          >
            <p>Ladders & Scaffolding</p>
          </div>
        </div>
        <div className={css.row}>
          <div
            onClick={() => handleSearch({ pub_category: '3DPrinters' })}
            className={css.littleBox}
          >
            <p>3D Printers</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'paintEquipment' })}
            className={css.littleBox}
          >
            <p>Paint Equipment</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'outdoorPowerTools' })}
            className={css.littleBox}
          >
            <p>Outdoor Power Tools</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'woodworkingTools' })}
            className={css.littleBox}
          >
            <p>Wood Working Tools</p>
          </div>
          <div
            onClick={() => handleSearch({ pub_category: 'plumbingTools' })}
            className={css.littleBox}
          >
            <p>Plumbing Tools</p>
          </div>
        </div>
      </Carousel>

      <div className={css.wrapper2}>
        <h2 className={css.title}>Tools by Brand</h2>
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          emulateTouch={true}
        >
          <div className={css.row}>
            <div onClick={() => handleSearch({ pub_brand: 'BOSCH' })} className={css.littleBox}>
              <p>BOSCH</p>
            </div>
            <div onClick={() => handleSearch({ pub_brand: 'DELTA' })} className={css.littleBox}>
              <p>DELTA</p>
            </div>
            <div onClick={() => handleSearch({ pub_brand: 'DeWALT' })} className={css.littleBox}>
              <p>DeWALT</p>
            </div>
            <div onClick={() => handleSearch({ pub_brand: 'HILTI' })} className={css.littleBox}>
              <p>HILTI</p>
            </div>
          </div>
          <div className={css.row}>
            <div onClick={() => handleSearch({ pub_brand: 'HUSKY' })} className={css.littleBox}>
              <p>HUSKY</p>
            </div>
            <div onClick={() => handleSearch({ pub_brand: 'RIDGID' })} className={css.littleBox}>
              <p>RIDGID</p>
            </div>
            <div onClick={() => handleSearch({ pub_brand: 'Milwaukee' })} className={css.littleBox}>
              <p>Milwaukee</p>
            </div>
            <div onClick={() => handleSearch({ pub_brand: 'RYOBI' })} className={css.littleBox}>
              <p>RYOBI</p>
            </div>
          </div>
          <div className={css.row}>
            <div onClick={() => handleSearch({ pub_brand: 'Makita' })} className={css.littleBox}>
              <p>Makita</p>
            </div>
            <div onClick={() => handleSearch({ pub_brand: 'STANLEY' })} className={css.littleBox}>
              <p>STANLEY</p>
            </div>
            <div
              onClick={() => handleSearch({ pub_brand: 'porterCable' })}
              className={css.littleBox}
            >
              <p>PORTER CABLE</p>
            </div>
            <div
              onClick={() => handleSearch({ pub_brand: 'drillAmerica' })}
              className={css.littleBox}
            >
              <p>Drill America</p>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default SlideShow;
