import React, { useState, useEffect } from 'react';
import { Modal, Button } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { propTypes } from '../../util/types';
import { pushToPath } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import css from './TopbarDesktop.module.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { daysBetween, nightsBetween } from '../../util/dates';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { NamedLink } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { setInitialValues } from '../../containers/CheckoutPage/CheckoutPage.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedDate } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { getSalesTax } from '../TaxJarInfo/utils';
const { UUID } = sdkTypes;
const CUSTOMER_COMMISSION_PERCENTAGE = 15;
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
const dateFormatOptions = {
  month: 'short',
  day: 'numeric',
};
const calculateQuantityFromDates = (startDate, endDate, type) => {
  if (type === LINE_ITEM_NIGHT) {
    return nightsBetween(startDate, endDate);
  } else if (type === LINE_ITEM_DAY) {
    return daysBetween(startDate, endDate);
  }
  return 1;
};

const { Money } = sdkTypes;

const ShoppingCartComponent = props => {
  const { mobile, intl, callSetInitialValues, currentLocation } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [shoppingCartItems, setShoppingCartItems] = useState([]);

  useEffect(() => {
    sdk.currentUser
      .show()
      .then(res => {
        const shoppingCart = res.data.data.attributes.profile.publicData.shoppingCart;
        if (shoppingCart && shoppingCart?.length > 0) {
          setShoppingCartItems(
            shoppingCart.map(item => {
              return {
                listing: JSON.parse(item.listing),
                checkoutValues: JSON.parse(item.checkoutValues),
                salesTaxAmount: item.salesTaxAmount,
              };
            })
          );
        }
      })
      .catch(e => console.log(e));
  }, [isOpen]);

  // ############
  //         const finalShoppingCartItems = shoppingCart.map(item => {
  //           const lineItems = JSON.parse(item.lineItems);
  //           const listing = JSON.parse(item.listing);
  //           return getSalesTax(lineItems, listing).then(resp => {
  //             const salesTaxAmount =
  //               resp?.tax?.amount_to_collect && 100 * resp?.tax?.amount_to_collect;
  //             const finalItem = {
  //               ...item,
  //               salesTaxAmount,
  //             };
  //             return finalItem;
  //           });
  //         });

  //         return Promise.all(finalShoppingCartItems)
  //           .then(resp => {
  //             setShoppingCartItems(
  //               resp.map(item => {
  //                 return {
  //                   listing: JSON.parse(item.listing),
  //                   checkoutValues: JSON.parse(item.checkoutValues),
  //                   salesTaxAmount: item.salesTaxAmount,
  //                 };
  //               })
  //             );
  //           })
  //           .catch(e => {
  //             setShoppingCartItems(
  //               shoppingCart.map(item => {
  //                 return {
  //                   listing: JSON.parse(item.listing),
  //                   checkoutValues: JSON.parse(item.checkoutValues),
  //                 };
  //               })
  //             );
  //           });
  //       }
  //     })
  //     .catch(e => console.log(e));
  // }, [isOpen]);

  const deleteItem = id => {
    let newShoppingCart = [...shoppingCartItems];

    const indexOfRemovingItem = newShoppingCart.findIndex(item => {
      return item.listing.id.uuid === id;
    });

    if (indexOfRemovingItem > -1) {
      newShoppingCart.splice(indexOfRemovingItem, 1); // 2nd parameter means remove one item only
    }

    return sdk.currentUser
      .updateProfile({
        publicData: {
          shoppingCart: newShoppingCart.map(item => {
            return {
              listing: JSON.stringify({ ...item.listing }),
              checkoutValues: JSON.stringify({ ...item.checkoutValues }),
            };
          }),
        },
      })
      .then(res => {
        setShoppingCartItems(newShoppingCart);
      })
      .catch(e => console.log(e));
  };

  let totalPrice;
  let damageProtectionPrice;
  let customerCommisionPrice;

  if (shoppingCartItems.length > 0) {
    const amountsArray = shoppingCartItems
      .filter(x => x.checkoutValues.bookingDates)
      .map(i => {
        const startDate = new Date(i.checkoutValues.bookingDates.startDate);
        const endDate = new Date(i.checkoutValues.bookingDates.endDate);
        const bookingQuantity = calculateQuantityFromDates(
          startDate,
          endDate,
          config.bookingUnitType
        );
        return i.listing.attributes.price.amount * bookingQuantity;
      });

    const totalAmount = amountsArray.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    const damageProtectionAmount = totalAmount * 0.1;
    damageProtectionPrice = intl
      ? formatMoney(intl, new Money(damageProtectionAmount, config.currency))
      : `${damageProtectionAmount / 100} ${config.currency}`;

    const customerCommisionAmount = totalAmount * (CUSTOMER_COMMISSION_PERCENTAGE / 100);
    const finalCustomerCommisionAmount =
      customerCommisionAmount < 500 ? 500 : customerCommisionAmount;

    customerCommisionPrice = intl
      ? formatMoney(intl, new Money(finalCustomerCommisionAmount, config.currency))
      : `${finalCustomerCommisionAmount / 100} ${config.currency}`;

    const salesTaxAmount = shoppingCartItems.reduce((acc, curr) => {
      return acc + curr.salesTaxAmount;
    }, 0);

    const totalPriceAmount =
      totalAmount + damageProtectionAmount + finalCustomerCommisionAmount + salesTaxAmount;
    totalPrice = intl
      ? formatMoney(intl, new Money(totalPriceAmount, config.currency))
      : `${totalPriceAmount / 100} ${config.currency}`;
  }

  const toCheckout = () => {
    const { history } = props;
    const listingId = new UUID(shoppingCartItems[0].listing.id.uuid);
    const listing = shoppingCartItems[0].listing;
    const proposedPrice =
      shoppingCartItems[0] && shoppingCartItems[0].checkoutValues?.proposedPrice;

    const bookingData = shoppingCartItems[0].checkoutValues;

    if (proposedPrice) {
      bookingData.proposedPrice = proposedPrice;
    }

    const firstItemSalesTax = shoppingCartItems[0]?.salesTaxAmount;
    const restOfShoppingCartItems = [...shoppingCartItems];
    restOfShoppingCartItems.shift();
    bookingData.restOfShoppingCartItems = restOfShoppingCartItems;

    const bookingDates = {
      startDate: new Date(shoppingCartItems[0].checkoutValues.bookingDates.startDate),
      endDate: new Date(shoppingCartItems[0].checkoutValues.bookingDates.endDate),
    };

    const initialValues = {
      listing,
      bookingData: {
        ...bookingData,
        currentLocation,
        salesTaxAmount: firstItemSalesTax,
      },
      bookingDates: bookingDates
        ? {
            bookingStart: bookingDates.startDate,
            bookingEnd: bookingDates.endDate,
          }
        : {},
      confirmPaymentError: null,
    };

    const saveToSessionStorage = true;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    // const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    initializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  };

  const salesTaxAmount = shoppingCartItems?.reduce((acc, curr) => {
    return acc + curr?.salesTaxAmount;
  }, 0);
  const salesTaxLabel =
    intl && salesTaxAmount
      ? formatMoney(intl, new Money(salesTaxAmount, config.currency))
      : salesTaxAmount
      ? `${salesTaxAmount / 100} ${config.currency}`
      : null;
  const damageProtectionLineItem = (
    <div className={css.cartItem}>
      <div>{'10% damage protection'}</div>
      <div>{damageProtectionPrice}</div>
    </div>
  );

  const customerCommisionLineItem = (
    <div className={css.cartItem}>
      <div>{'Processing fee *'}</div>
      <div>{customerCommisionPrice}</div>
    </div>
  );

  const totalSalesTaxLineItem = (
    <div className={css.cartItem}>
      <div>{'Sales tax *'}</div>
      <div>{salesTaxLabel}</div>
    </div>
  );

  return (
    <>
      <div className={css.shoppingCartWrapper} onClick={() => setIsOpen(true)}>
        {mobile ? (
          <span className={css.mobileLabel}>
            <FormattedMessage id="ShoppingCart.mobileLabel" />
          </span>
        ) : (
          <ShoppingCartIcon className={css.cartIcon} />
        )}
        {shoppingCartItems.length > 0 ? (
          <div className={css.dotInfo}>{shoppingCartItems.length}</div>
        ) : null}
      </div>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onManageDisableScrolling={() => {}}
        doubleModal={mobile}
      >
        {shoppingCartItems.length === 0 ? (
          <>
            {' '}
            {mobile ? <br /> : null}
            <center>
              <h2>
                <FormattedMessage id="ShoppingCart.emptyTitle" />
              </h2>
            </center>
            <br />
            <NamedLink className={css.searchButton} name="SearchPage">
              <FormattedMessage id="ShoppingCart.searchListing" />
            </NamedLink>
          </>
        ) : (
          <div className={css.cartItemsWrapper}>
            {shoppingCartItems
              .filter(x => x.checkoutValues.bookingDates)
              .map(item => {
                const startDate = new Date(item.checkoutValues.bookingDates.startDate);
                const endDate = new Date(item.checkoutValues.bookingDates.endDate);
                const bookingQuantity = calculateQuantityFromDates(
                  startDate,
                  endDate,
                  config.bookingUnitType
                );
                const totalItemAmount = item.listing.attributes.price.amount * bookingQuantity;
                const totalPriceOfItem = new Money(totalItemAmount, config.currency);
                const formattedPrice = intl
                  ? formatMoney(intl, totalPriceOfItem)
                  : `${totalItemAmount / 100} ${totalPriceOfItem.currency}`;

                const itemTitle =
                  item.listing.attributes.title.length > 40
                    ? item.listing.attributes.title.slice(0, 37) + '...'
                    : item.listing.attributes.title;

                const startDateRangeLabel = (
                  <FormattedDate value={startDate} {...dateFormatOptions} />
                );
                const endDateRangeLabel = <FormattedDate value={endDate} {...dateFormatOptions} />;

                return (
                  <div className={css.cartItem}>
                    <div>
                      <span>
                        <a
                          className={css.lineItemLabel}
                          onClick={() =>
                            pushToPath(
                              `/l/${item.listing.attributes.title.replace(' ', '-')}/${
                                item.listing.id.uuid
                              }`
                            )
                          }
                        >
                          {itemTitle}
                          <div className={css.dateRangeLabel}>
                            ( {startDateRangeLabel} - {endDateRangeLabel} )
                          </div>
                        </a>{' '}
                        {/* x {item.checkoutValues.quantity} */}
                      </span>
                    </div>
                    <div className={css.lineItemPriceWrapper}>
                      <DeleteOutlineIcon
                        className={css.deleteIcon}
                        onClick={() => deleteItem(item.listing.id.uuid)}
                      />

                      <span>{formattedPrice}</span>
                    </div>
                  </div>
                );
              })}

            {damageProtectionLineItem}
            {customerCommisionLineItem}
            {totalSalesTaxLineItem}

            <div className={css.total}>
              <span>
                {' '}
                <FormattedMessage id="ShoppingCart.total" />
              </span>

              <span>{totalPrice}</span>
            </div>

            <br />
            <Button type="button" onClick={toCheckout}>
              <FormattedMessage id="ShoppingCart.checkout" />
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

ShoppingCartComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  filterConfig: config.custom.filters,
};

ShoppingCartComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;

  return {
    isAuthenticated,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

const ShoppingCart = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ShoppingCartComponent);

export default ShoppingCart;
