const checkWeekdays = days => {
  const date1 = new Date(days.split(',')[0]);
  const date2 = new Date(days.split(',')[1]);
  const day1 = date1.getUTCDay();
  const day2 = date2.getUTCDay();
  if (day1 < 1 || day1 > 5) {
    if (day2 < 1 || day2 > 5) {
      return 'weekdaysDisabled';
    } else {
      return 'combined';
    }
  } else {
    if (day2 < 1 || day2 > 5) {
      return 'combined';
    } else {
      return 'weekendsDisabled';
    }
  }
};

const getAvailabilityRuleSearchString = availabilityRuleType => {
  switch (availabilityRuleType) {
    case 'weekendsDisabled':
      return 'weekendsDisabled,custom';
    case 'weekdaysDisabled':
      return 'weekdaysDisabled,custom';
    default:
      return 'custom';
  }
};

export const getAvailabilityRuleParam = params => {
  const dates = params?.dates;
  if (dates) {
    const availabilityRuleType = checkWeekdays(dates);
    const availabilityRuleSearchString = getAvailabilityRuleSearchString(availabilityRuleType);
    return {
      pub_availabilityRule: availabilityRuleSearchString,
    };
  } else {
    return {};
  }
};
