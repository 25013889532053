export const getInitialDatesFromSearch = searchDates => {
  const startDateSearch = searchDates ? searchDates[0] : false;
  const endDateSearch = searchDates ? searchDates[1] : false;
  if (startDateSearch) {
    startDateSearch.setHours(12);
  }
  if (endDateSearch) {
    endDateSearch.setHours(12);
  }

  return {
    bookingDates: {
      startDate: startDateSearch,
      endDate: endDateSearch,
    },
  };
};
