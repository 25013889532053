import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import css from './SectionHero.module.css';
import { stringifyDateToISO8601 } from '../../util/dates';
import { userLocation } from '../../util/maps';
import Geocoder, {
  GeocoderAttribution,
  CURRENT_LOCATION_ID,
} from '../LocationAutocompleteInput/GeocoderMapbox';

const SectionHero = props => {
  const { rootClassName, className, location } = props;
  const [currentLocation, setCurrentLocation] = useState(null);

  const getGeocoder = () => {
    const geocoder = new Geocoder();

    return geocoder;
  };
  useEffect(() => {
    getGeocoder()
      .getPlaceDetails({
        id: 'current-location',
        predictionPlace: {},
      })
      .then(place => {
        place.address = 'Current location';
        const currentLocationObject = {
          search: place.address,
          predictions: [],
          selectedPlace: place,
        };
        if (currentLocationObject?.selectedPlace) {
          setCurrentLocation(currentLocationObject);
        }
      })
      .catch(e => console.log(e));
  }, []);

  const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const handleSubmitSearch = values => {
    const { currentSearchParams } = props;
    let keywordsObj = {};
    let locationObj = {};
    let datesObj = {};

    const search = values?.location?.search;
    const selectedPlace = values?.location?.selectedPlace;
    const origin = selectedPlace?.origin;
    const bounds = selectedPlace?.bounds;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    locationObj = {
      ...originMaybe,
      address: search,
      bounds,
    };

    if (values.dates) {
      datesObj = {
        dates: values.dates,
      };
    }

    if (values.keywords) {
      keywordsObj = {
        keywords: values.keywords,
      };
    }
    const { history } = props;

    const searchParams = {
      ...currentSearchParams,
      ...locationObj,
      ...datesObj,
      ...keywordsObj,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : currentLocation || null,
  };

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={handleSubmitSearch}
      // handleSubmitSearch={onSearchSubmit}
      initialValues={initialSearchFormValues}
      currentLocation={currentLocation}
    />
  );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        {/* New Sweepstakes Announcement */}
    <div className={css.sweepstakesAnnouncement}>
      <h3>#1 Tool Sharing Platform!</h3>
    </div>
    
    <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        {/* <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2> */}
        {search}

        {/* <NamedLink
          name="SearchPage"
          to={{
            search:
              ' ',
          }}
          className={css.heroButton}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink> */}
      </div>
     
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
