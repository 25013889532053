import React, { useState, useEffect } from 'react';
import Rating from '@mui/material/Rating';
import { getListingRatingAvg } from '../../util/userInfo';
import css from './ListingAverageRating.module.css';

const ListingAverageRating = props => {
  const { listingId } = props;
  const [averageRating, setAverangeRating] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);

  useEffect(() => {
    if (listingId) {
      getListingRatingAvg(listingId)
        .then(resp => {
          const ratingAvg = resp?.ratingAvg;
          const reviewsCount = resp?.reviewsCount;
          setAverangeRating(ratingAvg);
          setReviewsCount(reviewsCount);
        })
        .catch(e => {
          console.log(e);
          //ignore error
        });
    }
  }, [listingId]);

  const reviewsCountLabel =
    '( ' + reviewsCount + (reviewsCount > 1 ? ' reviews' : ' review') + ') ';

  return (
    <div className={css.wrapper}>
      {averageRating ? (
        <div className={css.reviewsAvgWrapper}>
          <Rating
            name="read-only-rating"
            className={css.rating}
            value={averageRating}
            precision={0.1}
            readOnly
          />
          <span className={css.label}>{reviewsCountLabel}</span>
        </div>
      ) : (
        'No reviews'
      )}
    </div>
  );
};

export default ListingAverageRating;
