import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const fieldRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.fieldRequired',
      });
      const projectPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.projectPlaceholder',
      });
      const projectLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.projectLabel',
      });

      const brandPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.brandPlaceholder',
      });
      const brandLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.brandLabel',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const projectOptions = findOptionsForSelectFilter('project', config.custom.filters);
      const projectRequired = required(
        intl.formatMessage({
          id: 'EditListingDescriptionForm.projectRequired',
        })
      );

      const brandOptions = findOptionsForSelectFilter('brand', config.custom.filters);
      const brandRequired = required(
        intl.formatMessage({
          id: 'EditListingDescriptionForm.brandRequired',
        })
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <FieldTextInput
            id="serialNumber"
            name="serialNumber"
            className={css.serialNumber}
            type="text"
            label={'Serial number'}
            placeholder={'Type here...'}
            validate={composeValidators(required(fieldRequiredMessage))}
          />

          <p className={css.infoText}>
            *This would help is identify the tool if it’s lost or stolen.
          </p>

          <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            categories={[...categories, { key: 'other', label: 'Other' }]}
            intl={intl}
          />

          {values?.category && values?.category === 'other' && (
            <FieldTextInput
              id="otherCategory"
              name="otherCategory"
              className={css.title}
              type="text"
              label={'Other tool type'}
              placeholder={'Type here...'}
              validate={composeValidators(required(fieldRequiredMessage))}
            />
          )}

          {/* <FieldSelect
            id="project"
            name="project"
            className={css.category}
            label={projectLabel}
            validate={projectRequired}
          >
            <option disabled value="">
              {projectPlaceholder}
            </option>
            {projectOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect> */}

          <FieldSelect
            id="brand"
            name="brand"
            className={css.category}
            label={brandLabel}
            validate={brandRequired}
          >
            <option disabled value="">
              {brandPlaceholder}
            </option>
            {brandOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
            <option key={'other'} value={'other'}>
              {'Other'}
            </option>
          </FieldSelect>

          {values?.brand && values?.brand === 'other' && (
            <FieldTextInput
              id="otherBrand"
              name="otherBrand"
              className={css.title}
              type="text"
              label={'Other brand'}
              placeholder={'Type here...'}
              validate={composeValidators(required(fieldRequiredMessage))}
            />
          )}

          <label className={css.fieldLabel}>Stock</label>
          <Field component="input" name="stock" id="stock" type="number" placeholder="2" />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
