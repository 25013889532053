import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { FormattedDate } from '../../util/reactIntl';
import css from './BookingBreakdown.module.css';
import { dateFromAPIToLocalNoon } from '../../util/dates';
const dateFormatOptions = {
  month: 'short',
  day: 'numeric',
};
const LineItemBasePriceMaybe = props => {
  const { transaction, intl, listing, unitType, restOfShoppingCartItems, booking } = props;
  // const lineItemCode = props.unitType;
  // console.log(lineItemCode);
  // const unitType = lineItemCode.split('||')[0];
  // const listingId = lineItemCode.split('||')[1];
  const isOnListingPage =
    Array.isArray(restOfShoppingCartItems) && restOfShoppingCartItems.length === 0;
  const start = booking?.attributes?.start;
  const end = booking?.attributes?.end;
  const displayEnd = booking?.attributes?.displayEnd;
  const displayStart = booking?.attributes?.displayStart;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchaseArray = transaction.attributes.lineItems.filter(item => {
    return item.code.includes(unitType) && !item.reversal;
  });

  const breakdownItems = unitPurchaseArray.map(unitPurchase => {
    const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
    const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
    const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
    const currentListing = transaction?.listing ? transaction?.listing : listing;
    const listingId = unitPurchase.code.split('||')[1];
    let baseListing = currentListing;

    const localStartDate = dateFromAPIToLocalNoon(displayStart || start);
    const localEndDateRaw = dateFromAPIToLocalNoon(displayEnd || end);

    let startDateRangeLabel = <FormattedDate value={localStartDate} {...dateFormatOptions} />;
    let endDateRangeLabel = <FormattedDate value={localEndDateRaw} {...dateFormatOptions} />;

    restOfShoppingCartItems.forEach(item => {
      if (listingId === item.listing.id.uuid) {
        baseListing = item.listing;
        const shoppingCartItemStartDate = new Date(item.checkoutValues.bookingDates.startDate);
        const shoppingCartItemEndDate = new Date(item.checkoutValues.bookingDates.endDate);
        startDateRangeLabel = (
          <FormattedDate value={shoppingCartItemStartDate} {...dateFormatOptions} />
        );
        endDateRangeLabel = (
          <FormattedDate value={shoppingCartItemEndDate} {...dateFormatOptions} />
        );
      }
    });

    const dateRangeLabel = (
      <div className={css.dateRangeLabel}>
        ( {startDateRangeLabel} - {endDateRangeLabel} )
      </div>
    );

    const listingTitle = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <a href={`/l/${baseListing?.attributes.title.replace(' ', '-')}/${baseListing?.id.uuid}`}>
            {baseListing?.attributes.title} {dateRangeLabel}
          </a>
        </span>
      </div>
    );

    return quantity && total ? (
      <>
        {!isOnListingPage && listingTitle}

        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
          </span>
          <span className={css.itemValue}>{total}</span>
        </div>
      </>
    ) : null;
  });

  return breakdownItems;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
