import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldCheckboxGroup } from '../../components';
import arrayMutators from 'final-form-arrays';

import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import css from './EditListingAvailabilityForm.module.css';

export class EditListingAvailabilityFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            ready,
            handleSubmit,
            //intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateError,
            updateInProgress,
            availability,
            availabilityPlan,
            listingId,
            values,
          } = formRenderProps;

          const errorMessage = updateError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
            </p>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          const availabilityRuleOptions = [
            {
              key: 'custom',
              label: 'Custom',
            },
            {
              key: 'weekdaysDisabled',
              label: 'Only weekends',
            },
            {
              key: 'weekendsDisabled',
              label: 'Only weekdays',
            },
          ];

          const testIsWeekendDay = date => {
            return new Date(date).getDay() === 0 || new Date(date).getDay() === 6;
          };

          const isDayBlockedByRule = day => {
            const isWeekendDay = testIsWeekendDay(day);
            if (values.availabilityRule === 'weekdaysDisabled') {
              return !isWeekendDay;
            } else if (values.availabilityRule === 'weekendsDisabled') {
              return isWeekendDay;
            } else {
              return false;
            }
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}

              <FieldCheckboxGroup
                className={css.features}
                id={'availabilityRule'}
                name={'availabilityRule'}
                options={availabilityRuleOptions}
                row={true}
                radio={true}
              />
              <div className={css.calendarWrapper}>
                <ManageAvailabilityCalendar
                  availability={availability}
                  availabilityPlan={availabilityPlan}
                  listingId={listingId}
                  isDayBlockedByRule={isDayBlockedByRule}
                />
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
