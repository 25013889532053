import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-florida',
    predictionPlace: {
      address: 'Florida, United States',
      bounds: new LatLngBounds(
        new LatLng(31.50813836, -77.51070497),
        new LatLng(24.43034000876, -87.6347449003925)
      ),
    },
  },
  {
    id: 'default-newYork',
    predictionPlace: {
      address: 'New York, United States',
      bounds: new LatLngBounds(new LatLng(40.917577, -73.700272), new LatLng(40.477399, -74.25909)),
    },
  },
  {
    id: 'default-losAngeles',
    predictionPlace: {
      address: 'Los Angeles, United States',
      bounds: new LatLngBounds(
        new LatLng(34.161439, -118.126728),
        new LatLng(33.900014, -118.521447)
      ),
    },
  },
  {
    id: 'default-chicago',
    predictionPlace: {
      address: 'Chicago, United States',
      bounds: new LatLngBounds(
        new LatLng(42.023135, -87.523907),
        new LatLng(41.644286, -87.869226)
      ),
    },
  },
  {
    id: 'default-washington',
    predictionPlace: {
      address: 'Washington, United States',
      bounds: new LatLngBounds(
        new LatLng(49.01214908, -116.91607066),
        new LatLng(45.5435405, -124.86219)
      ),
    },
  },
];
export default defaultLocations;
