import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './SetCoverButton.module.css';

const SetCoverButton = props => {
  const {
    coverImageId,
    setCoverImageId,
    image,
    index,
    onUpdateListing,
    currentListing,
    isSetAsCover,
    imageUUID,
  } = props;

  const handleSetAsCover = () => {
    if (!isSetAsCover) {
      onUpdateListing('photos', {
        id: currentListing.id,
        publicData: {
          coverImage: imageUUID,
        },
      });
      setCoverImageId(imageUUID);
    }
  };

  return (
    <div className={isSetAsCover ? css.wrapperSelected : css.wrapper} onClick={handleSetAsCover}>
      <FormattedMessage
        id={isSetAsCover ? 'SetCoverButton.coverLabel' : 'SetCoverButton.setLabel'}
      />
    </div>
  );
};

export default SetCoverButton;
